import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://benswap.finance/'
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: 'Pancake DEX',
        href: 'https://exchange.benswap.finance/'
      },
      {
        label: 'BenSwap DEX',
        href: '/swap',
        isActive: true
      }
    ]
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: 'https://benswap.finance/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: 'https://benswap.finance/pools',
  },
  {
    label: 'Town',
    icon: 'TownIcon',
    items: [
      {
        label: "Temple",
        href: "https://benswap.finance/temple",
      },
    ],
  },
  {
    label: 'Lottery',
    icon: 'TicketIcon',
    href: 'https://benswap.finance/lottery',
  },
  {
    label: 'Audits',
    icon: 'AuditIcon',
    items: [
      {
        label: "TechRate",
        href: "https://github.com/TechRate/Smart-Contract-Audits/blob/main/2018-21%20A-M/Benswap.pdf",
      },
      {
        label: "RugDoc",
        href: "https://rugdoc.io/project/benswap/",
      },
    ],
  },
  {
    label: 'Listings',
    icon: 'ListingIcon',
    items: [
      {
        label: "DappRadar",
        href: "https://dappradar.com/binance-smart-chain/exchanges/benswap",
      },
      {
        label: "Defi Llama",
        href: "https://defillama.com/protocol/benswap",
      },
      {
        label: "PancakeSwap Info",
        href: "https://pancakeswap.info/token/0x8173dda13fd405e5bca84bd7f64e58caf4810a32/",
      },
      {
        label: "BscScan",
        href: "https://bscscan.com/token/0x8173dda13fd405e5bca84bd7f64e58caf4810a32",
      },
      {
        label: "Trust Wallet",
        href: "https://github.com/trustwallet/assets/blob/master/blockchains/smartchain/assets/0x8173dDa13Fd405e5BcA84Bd7F64e58cAF4810A32/info.json",
      },
      {
        label: "LiveCoinWatch",
        href: "https://www.livecoinwatch.com/price/GoldenBen-GBEN",
      },
      {
        label: "Coinhunt",
        href: "https://coinhunt.cc/coin/1585466354",
      },
      {
        label: "CoinSniper",
        href: "https://coinsniper.net/coin/4739",
      },
    ],
  },
  {
    label: "Info",
    icon: "InfoIcon",
    items: [
      {
        label: "Overview",
        href: "https://info.benswap.finance",
      },
      {
        label: "Tokens",
        href: "https://info.benswap.finance/tokens",
      },
      {
        label: "Pairs",
        href: "https://info.benswap.finance/pairs",
      },
      {
        label: "Accounts",
        href: "https://info.benswap.finance/accounts",
      },
      {
        label: "ChartPro",
        href: "https://chartpro.benswap.finance/",
      },
      {
        label: "vfat.tools",
        href: "https://vfat.tools/bnb/benswap/",
      },
    ],
  },
  {
    label: 'Ecosystem',
    icon: 'BenIcon',
    items: [
      {
        label: "Ben Token",
        href: "https://bentoken.finance/",
      },
      {
        label: "BenSwap (smartBCH)",
        href: "https://benswap.cash/",
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: "Github",
        href: "https://github.com/BenTokenFinance",
      },
      {
        label: "Docs",
        href: "https://docs.benswap.finance/",
      },
    ],
  },
]

export default config
